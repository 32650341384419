.banner_cnt {
  background-image: url("../../../public/banner4.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 30px;
  filter: brightness(0.9);
  padding-top: 120px;
  height: 100vh;
}
.bg_cnt2 {
  width: 100%;
  padding: 10px 80px;
  margin-top: 35px;
}
.anime_card_cnt {
  display: flex;
  gap: 15px;
  width: 100%;
}
.anime_card {
  flex-grow: 1;
  width: 100%;
}

/* Card 1*/

.feature_card_cnt1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/f1.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
  transition: 0.5s ease-in-out;
  padding: 50px 5px;
  border-radius: 5px;
  /* box-shadow: 0 26px 58px 0 rgba(0, 0, 0, 0.22),
    0 5px 14px 0 rgba(0, 0, 0, 0.18); */
  margin: 25px;
}

.feature_card_cnt1 h1 {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 3px;
}
.feature_card_cnt1:hover .feature_card_desc {
  display: block;
  color: #b3a9a9;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}
.feature_card_cnt1:hover {
  cursor: pointer;
  transform: scale(1.05);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/f1.jpg");
}

@media (max-width: 780px) {
  .feature_card_cnt1 {
    /* height: 300px; */
    margin: 0px;
    margin-bottom: 25px;
    padding: 15px;
  }
  .feature_card_cnt1:hover {
    cursor: pointer;
    transform: scale(1);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../assets/f1.jpg");
  }
}

/* card2 */

.feature_card_cnt2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/f2.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
  transition: 0.5s ease-in-out;
  padding: 50px 5px;
  border-radius: 5px;
  /* box-shadow: 0 26px 58px 0 rgba(0, 0, 0, 0.22),
    0 5px 14px 0 rgba(0, 0, 0, 0.18); */
  margin: 25px;
}

.feature_card_cnt2 h1 {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 3px;
}
.feature_card_cnt2:hover .feature_card_desc {
  display: block;
  color: #b3a9a9;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}
.feature_card_cnt2:hover {
  cursor: pointer;
  transform: scale(1.05);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/f2.jpg");
}
@media (max-width: 780px) {
  .feature_card_cnt2 {
    /* height: 300px; */
    margin: 0px;
    margin-bottom: 25px;
  }
  .feature_card_cnt2:hover {
    cursor: pointer;
    transform: scale(1);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../assets/f2.jpg");
  }
}
/* card3 */

.feature_card_cnt3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/f3.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
  transition: 0.5s ease-in-out;
  padding: 50px 5px;
  border-radius: 5px;
  /* box-shadow: 0 26px 58px 0 rgba(0, 0, 0, 0.22),
    0 5px 14px 0 rgba(0, 0, 0, 0.18); */
  margin: 25px;
}

.feature_card_cnt3 h1 {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 3px;
}
.feature_card_cnt3:hover .feature_card_desc {
  display: block;
  color: #b3a9a9;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}
.feature_card_cnt3:hover {
  cursor: pointer;
  transform: scale(1.05);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/f3.jpg");
}

@media (max-width: 780px) {
  .feature_card_cnt3 {
    /* height: 300px; */
    margin: 0px;
    margin-bottom: 25px;
  }
  .feature_card_cnt3:hover {
    cursor: pointer;
    transform: scale(1);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url("../../assets/f3.jpg");
  }
}

/* END */


.heading {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 40px;
  color: #02334d;
  /* text-transform: uppercase; */
  margin-top: 25px;
}
.card_content{
  background: #ECE9E6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
   /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
padding: 15px !important;
border-radius: 8px;
}
.heading_card{
  font-size: 25px !important;
  margin:auto !important;
}
.card_desc{
  font-size: 20px !important;
  /* color: #000000 !important; */
}



.banner_heading_cnt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.banner_heading_cnt h1 {
  color: #02334d;
  font-family: "Gambetta", serif;
  font-size: 60px;
  font-weight: 500;
  letter-spacing: 3px;
  width: 60vw;
  /* text-shadow: 3px 8px 6px rgba(0,0,0,0.4), 0px -5px 11px rgba(255,255,255,0.4); */
}

.banner_heading_cnt p {
  color: #000000;
  font-size: 26px;
  font-weight: 500;
  margin-top: 10px;
  font-family: Courier;
}
.banner_desc {
  width: 60vw;
  font-family: cursive;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 20px;
  color: #02334d;
}

/* card1 */

.products_cnt {
  width: 100%;
  margin: 25px 0px;
  display: flex;
  align-items: center;
}
.feature_card_desc {
  display: none;
}

.slider_container {
  background-color: rgba(0, 0, 0, 0.5);
}

.c_img {
  max-height: 200px;
  object-fit: contain;
}

.desc {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 20px;
  color: #02334d;
  margin-bottom: 25px;
}

.marquee_div {
  display: flex;
  align-items: center;
  gap: 100px;
}
.products_section {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 50px;
  margin: 25px 0px;
  padding: 10px;
}

@media (max-width: 770px) {
  .products_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    margin: 0px;
    padding: 0px;
  }
}

.home_product_cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #355C7D;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #C06C84, #6C5B7B, #355C7D);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #C06C84, #6C5B7B, #355C7D); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  /* box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; */
  padding: 20px;
  border-radius: 8px;
  transition: 0.5s ease;
  /* width: 100%; */
  flex-grow: 1;
  margin-bottom: 25px;
  width: 100%;
  position: relative;
}
/* .home_product_cnt::before {
  content: "";
  height: 100px;
  width: 100px;
  position: absolute;
  top: 0%;
  left: -20%;
  border-radius: 50%;
  border: 35px solid rgba(251, 249, 249, 0.2);
  transition: all 0.8s ease;
  filter: blur(0.5rem);
}
.home_product_cnt:hover::before {
  width: 240px;
  height: 240px;
  top: 30%;
  left: 65%;
  filter: blur(0rem);
  border: 55px solid rgba(0, 0, 0, 0.2);
} */

.home_product_cnt h1 {
  background: #114357;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #F29492, #114357);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #F29492, #114357); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
   
    font-size: 26px;
  padding: 15px;
  border-radius: 4px;
  width: 100%;
  color: #000000;

  font-family: "Gambetta", serif;
  text-transform: uppercase;
 
  letter-spacing: 2px;
  margin: 20px;
  min-height: fit-content;
  /* height: 90px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-shadow: 3px 8px 6px rgba(0,0,0,0.4), 0px -5px 11px rgba(255,255,255,0.4); */
}

.home_product_cnt p {
  font-size: 21px;
  color: #e1dada;
  /* font-family: "Gambetta", serif; */
  /* font-family: Arial, Helvetica, sans-serif; */
  letter-spacing: 2px;
  font-weight: 200;
}
.home_product_img {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.products_section_cnt{
  display: flex;
  flex-direction: column;
  width: 100%;
  
}
.home_product_desc_cnt{
  display: flex;
  gap: 20px;
  width: 100%;
  background: #355C7D;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #C06C84, #6C5B7B, #355C7D);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #C06C84, #6C5B7B, #355C7D); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  padding: 10px;
  border-radius: 10px;
  align-items: center;
}
.product_img{
  object-fit: contain;
  height: 200px;
  width: 100%;
  mix-blend-mode: color-burn;
}
.testi_cnt{
  background: #F3904F;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3B4371, #F3904F);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3B4371, #F3904F); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 35px;
  margin-bottom: 45px;
}
.testi_div:focus{
  outline: none;
}
.testi_img{
  height: 500px;
  width: 100%;
  object-fit: contain;
}
.clients_container{
  background: #8e9eab;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #eef2f3, #8e9eab); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: "30px 0px";
}
.banner_cnt2{
  display: none;
}
@media (max-width: 780px) {
  .testi_cnt{
    display: none;
  }
 .banner_cnt2{
  display: block;
  padding: 15px;
  padding-top:100px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
  url("../../assets/about2.jpg");
  /* background-image: url('../../assets/about2.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
 }
  .banner_cnt {
    min-height: fit-content;
    height: 120vh;
    display: none;
  }
  .banner_heading_cnt h1 {
    width: fit-content;
    font-size: 30px;
    color: #fff;
  }
  .banner_heading_cnt p {
    width: fit-content;
    font-size: 20px;
    color: #fff;
    font-weight: 300;
  }
  .banner_desc {
    width: 90vw;
    color: #ECE9E6;
  }
  .bg_cnt2 {
    width: 100%;
    /* padding: 10px 50px; */
    margin-top: 35px;
    padding: 8px;
  }
  .anime_card_cnt {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    min-height: fit-content;
  }
  .anime_card {
    flex-grow: 1;
    width: 100%;
    min-height: fit-content;
  }
  .feature_top_cnt {
    text-align: left;
  }
  .heading {
    min-height: fit-content;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    padding: 10px;
    font-size: 25px;
    color:#02334d;
    margin-top: 15px;
  }
  .desc {
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px;
    font-size: 18px;
  }
}



