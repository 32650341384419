.obe_bg_cnt {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("../../assets/nirf.jpeg");
  background-size: cover;
  background-position: center;
  height: 90vh;
  width: 100%;
  /* position: fixed; */
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.obe_heading {
  color: orange;
  width: 100%;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
}

.obe_cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: brightness(1.5);
}
.obe_cnt h1 {
  color: #cccccc;
  font-size: 80px;
  font-family: "Gambetta", serif;
  text-transform: uppercase;
  word-spacing: 20px;
  -webkit-text-stroke: #cccccc 3px;
  letter-spacing: 3px;
  text-shadow: 3px 8px 6px rgba(0,0,0,0.4), 0px -5px 11px rgba(255,255,255,0.4);
}

.obe_card_cnt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: 0.5s ease;
  margin-bottom: 50px;
  border-radius: 5px;
  margin: 15px;
}
.obe_card_img {
  width: 400px;
  height: 250px;
  aspect-ratio: 16/9;
  border-radius: 15px;
}

/* .obe_card_cnt:hover{
  perspective: 500px;
  transform: scaleX(0.7);
  rotate: rotateY(45deg);
} */
.obe_card h1 {
  color: #02334d;
  margin: 30px 0px;
}
.obe_card p {
  color: #000000;
}

.obe_card_cnt2{
  border-left: 10px solid lightgray;
  padding-left: 15px;
  margin-bottom: 25px;
}



@media only screen and (max-width: 780px) {
  .obe_bg_cnt {
    height: 30vh;
    background-position: top;
  }
  .obe_bg_cnt h1 {
    font-size: 40px;
    text-shadow: 3px 8px 6px rgba(0,0,0,0.4), 0px -5px 11px rgba(255,255,255,0.4);
    padding: 10px;
    text-align: center;
  }
  .obe_card_cnt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
    margin: 10px;
    border-radius: 10px;
  }
  .obe_card_img {
    width: 100%;
    height: 300px;
    aspect-ratio: 16/9;
    border-radius: 15px;
  }
}