.erp_bg_cnt {
  padding-top: 85px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.erp_anime {
  height: 70%;
  width: 70%;
  margin-bottom: 25px;
}
.erp_card_cnt {
  /* display: flex;
  align-items: center;
  gap: 20px; */
  width: 100%;
  gap: 25px;
  padding: 20px 80px;
  background-color: bisque;
}
.erp_card h1 {
  color: #02334d;
  font-size: 40px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 3px;
}
.erp_card p {
  color: #454343;
  font-size: 23px;
  font-weight: 400;
  font-family: "Roboto";
  /* font-family: Arial, Helvetica, sans-serif; */
  letter-spacing: 1px;
}


.container {
  background-color: #02334d;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.container h1 {
  font-size: 50px;
  text-transform: uppercase;
  font-family: "Gambetta", serif;
  letter-spacing: 0px;
  transition: 700ms ease;
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  color: PaleGoldenRod;
  outline: none;
  text-align: center;
}

.container h1:hover {
  font-variation-settings: "wght" 582;
  letter-spacing: 2px;
}

.container p {
  font-size: 1.2em;
  line-height: 150%;
  text-align: center;
  color: MintCream;
  letter-spacing: 0.5px;
}

@media (max-width: 768px) {
  .container h1 {
    font-size: 30px;
    letter-spacing: normal;
  }
  .container p {
    font-size: 15px;
  }
  .erp_card h1 {
    font-size: 22px;
    letter-spacing: 1px;
    
  }
  .erp_card p {
    font-size: 14px;
    /* text-align: justify; */
  }
  .erp_card{
    padding:8px;
  }
  .erp_anime {
    height: 30%;
    width: 80%;
    margin-bottom: 10px;
  }
  .erp_card_cnt{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 8px;
    text-align: center;
    height: fit-content;
  }
}
