.obe_bg_cnt {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../assets/ewallet2.jpg");
  background-size: cover;
  height: 90vh;
  width: 100%;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.obe_heading {
  color: orange;
  width: 100%;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
}

.obe_cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: brightness(1.5);
}
.obe_cnt h1 {
  color: #cccccc;
  font-size: 80px;
  font-family: "Gambetta", serif;
  text-transform: uppercase;
  word-spacing: 20px;
  -webkit-text-stroke: #cccccc 3px;
  letter-spacing: 3px;
  margin: 20px;
  text-shadow: 3px 8px 6px rgba(0,0,0,0.4), 0px -5px 11px rgba(255,255,255,0.4);
}


.obe_card_cnt {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: 0.5s ease;
  margin-bottom: 50px;
  margin: 15px;
  border-radius: 5px;
}
.obe_card_img {
  width: 400px;
  height: 250px;
  aspect-ratio: 16/9;
  border-radius: 15px;
}

.obe_card h1 {
  color: #02334d;
  margin: 30px 0px;
}
.obe_card p {
  color: #000000;
}
.obe_card_cnt_two{
  display: flex;
  justify-content: space-around;
  gap: 10px;
  /* align-items: flex-start; */
  /* flex-wrap: wrap; */
  width: 100%;
  /* margin: 0px 25px;
  margin-right: 25px; */
  padding: 25px;
}



.obe_card_cnt2{
  border-left: 10px solid lightgray;
  padding-left: 15px;
  margin-bottom: 25px;
}


@media (max-width: 780px) {
  .obe_bg_cnt {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("../../assets/ewallet2.jpg");
    background-size: cover;
    background-position: calc();
    height: fit-content;
    width: 100%;
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .obe_cnt h1 {
    font-size: 30px;
    text-shadow: 3px 8px 6px rgba(0,0,0,0.4), 0px -5px 11px rgba(255,255,255,0.4);
    width: fit-content;
  }
  .obe_card_cnt_two{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
    border-radius: 10px;
    width: 100% !important;
  }
  .obe_card_cnt{
    width: 100% !important;
  }
  .obe_card_cnt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
    margin: 10px;
    border-radius: 10px;
  }
  .obe_card_cnt2{
    border-left: 10px solid lightgray;
    padding-left: 15px;
  }
  .obe_card_img {
    width: 100%;
    height: 300px;
    aspect-ratio: 16/9;
    border-radius: 15px;
  }
 
}