.slider_cnt{
  width: 60%;
  margin: auto;
}
.career_intro_card{
  /* background-image: url('../assets/careers1.jpg'); */
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
  url("../assets/careers1.jpg");
  background-size: cover;
  background-position: center;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom:4rem ;
  padding-top: 80px;
}

.career_intro_card h1{
  color: palegoldenrod;
  font-size: 70px;
  /* text-transform: uppercase; */
  font-family: "Gambetta", serif;
  letter-spacing: -3px;
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  outline: none;
  text-align: left;
  width: 50vw;
  margin-left: 25px;
}
.career_intro_card p{
  color: #fff;
  font-size:20px;
  font-family:Arial, Helvetica, sans-serif;
  /* letter-spacing: -3px; */
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  outline: none;
  text-align: left;
  width: 50vw;
  margin-left: 25px;
}



@media screen and (max-width: 768px) {
  .career_intro_card{
    height: fit-content;
    width: 100vw;
    margin-left: 0px;
    margin: 0px;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center; */
  }
  .career_intro_card h1{
    /* margin-top: 220px; */
    /* width: fit-content; */
    width: 90vw;
    margin-left: 0px;
    font-size: 30px;
    padding: 22px;
  }
 
  .career_intro_card p{
    width: 90vw;
    color: #fff;
    font-size: 16px;
  }
  .slider_cnt{
    width: 100%;
    margin: auto;
  }
  #testimonials{
    padding: 10px !important;
  }
}



.form_group{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
.form_group label{
  text-align: left;
  width: 100%;
}
.form_group input{
  text-align: left;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #02334d;
}