@import url('https://fonts.googleapis.com/css?family=Fira+Sans');

/*Variables*/
:root {
    --background-color: #353b48;
    --font: "Fira Sans", sans-serif;
    --white: #f5f6fa;
    --black: #0C0E10;
    --gray: #202425;
    --blue: #446182;
}

/*Mixins*/
@media (max-width: 770px) {
    .smallscreens {
        /* Content */
    }
}

/*Extends*/
.Ycenter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

/*Main Layout Styles*/
* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
}

body {
    font-family: var(--font);
    color: var(--white);
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(var(--black), var(--blue));
}

.background .ground {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25vh;
    background: var(--black);
}

@media (max-width: 770px) {
    .background .ground {
        height: 0vh;
    }
}

.container {
    position: relative;
    margin: 0 auto;
    width: 85%;
    height: 100vh;
    padding-bottom: 25vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@media (max-width: 770px) {
    .container {
        flex-direction: column;
        padding-bottom: 0vh;
    }
}

.left_section,
.right_section {
    position: relative;
}

/*Left Section Styles*/
.left_section {
    width: 40%;
}

@media (max-width: 770px) {
    .left_section {
        width: 100%;
        height: 40%;
        position: absolute;
        top: 10;
        right: 33%;

    }
    .left_section .inner_content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 1rem 0;
    }
}

.left_section .inner_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
}

.left_section .heading {
    text-align: center;
    font-size: 9em;
    line-height: 1.3em;
    margin: 2rem 0 0.5rem 0;
    padding: 0;
    text-shadow: 0 0 1rem #fefefe;
}

@media (max-width: 770px) {
    .left_section .heading {
        font-size: 7em;
        line-height: 1.15;
        margin: 0;
        
    }
}

.left_section .subheading {
    text-align: center;
    max-width: 480px;
    font-size: 1.5em;
    line-height: 1.15em;
    padding: 0 1rem;
    margin: 0 auto;
}

@media (max-width: 770px) {
    .left_section .subheading {
        font-size: 1.3em;
        line-height: 1.15;
        max-width: 100%;
    }
}

/*Right Section Styles*/
.right_section {
    width: 50%;
}

@media (max-width: 770px) {
    .right_section {
        width: 100%;
        height: 60%;
        position: absolute;
        bottom: 0;
        display: none;
    }
}

.right_section .svgimg {
    position: absolute;
    bottom: 0;
    padding-top: 10vh;
    padding-left: 1vh;
    max-width: 100%;
    max-height: 100%;
}

@media (max-width: 770px) {
    .right_section .svgimg {
        padding: 0;
        
    }
}

.right_section .bench_legs {
    fill: var(--black);
}

.right_section .top_bench,
.right_section .bottom_bench {
    stroke: var(--black);
    stroke-width: 1px;
    fill: #5B3E2B;
}

.right_section .bottom_bench path:nth-child(1) {
    fill: #402d20;
}

.right_section .lamp_details {
    fill: var(--gray);
}

.right_section .lamp_accent {
    fill: #262a2d;
}

.right_section .lamp_bottom {
    fill: linear-gradient(var(--gray), var(--black));
}

.right_section .lamp_light {
    fill: #EFEFEF;
}

@keyframes glow {
    0% {
        text-shadow: 0 0 1rem #fefefe;
    }
    50% {
        text-shadow: 0 0 1.85rem #ededed;
    }
    100% {
        text-shadow: 0 0 1rem #fefefe;
    }
}
