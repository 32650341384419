.about_bg_container {
  /* background-color: blue; */
  padding-top: 90px;
  background-image: url("../../assets/about.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.about_bg_container h1 {
  font-size: 50px;
  /* text-transform: uppercase; */
  font-family: "Gambetta", serif;
  /* letter-spacing: -3px; */
  /* transition: 700ms ease; */
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  color:#fff;
  outline: none;
  text-align: left;
  width: 50vw;
  /* line-height: 80px; */
  margin-left: 25px;
}
.about_bg_container p {
  font-size: 20px;
  /* text-transform: uppercase; */
  font-family: "Gambetta", serif;
  /* letter-spacing: -3px; */
  /* transition: 700ms ease; */
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  color: lightblue;
  outline: none;
  text-align: left;
  width: 50vw;
  /* line-height: 80px; */
  margin-left: 25px;
}

.mission_container {
  /* background-color: rgb(69, 108, 121); */
  padding: 100px;
}
.mission_container h1 {
  color: #02334d;
  /* font-size: 70px; */
  /* text-transform: uppercase; */
  font-family: "Gambetta", serif;
  /* letter-spacing: -3px; */
  /* transition: 700ms ease; */
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  outline: none;
  text-align: left;
  width: 50vw;
  /* line-height: 80px; */
  margin-left: 25px;
}
.mission_container p {
  color: #000000;
  margin-left: 25px;
  font-size: large;
  line-height: 40px;
}
.choose_bg_cnt {
  /* background-image: url('../../assets/about6.jpg'); */
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
  url("../../assets/about6.jpg"); */
  background-color: #02334d;
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  padding: 100px;
  min-height: 80vh;
}
.choose_bg_cnt h1 {
  color: palegoldenrod;
  font-size: 50px;
  /* text-transform: uppercase; */
  font-family: "Gambetta", serif;
  /* letter-spacing: -3px; */
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  outline: none;
  text-align: left;
  width: 50vw;
  margin-left: 25px;
}



.choose_card_cnt p {
  margin-left: 25px;
  font-family: "Gambetta", serif;
  font-size: 20px;
  color: palegoldenrod;
}
.choose_card_cnt {
  border: 1px solid #d5d5d5;
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.3);
}

.commitment_bg_cnt {
  /* background-image: url('../../assets/about6.jpg'); */
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
  url("../../assets/about6.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 100px;
  min-height: 80vh;
}

.commitment_bg_cnt h1 {
  color: orange;
  font-size: 40px;
  /* text-transform: uppercase; */
  font-family: "Gambetta", serif;
  /* letter-spacing: -3px; */
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  outline: none;
  text-align: left;
  margin-bottom: 20px;
  width: 90vw;
  /* margin-left: 25px; */
}
.commitment_bg_cnt p{
  font-size: 20px;
  /* width:90vw; */
}

.commitment_card_cnt {
  background-color: #d5d5d5;
  border-left: 10px solid #02334d;
  padding: 10px;
  margin-bottom: 25px;
}
.commitment_card_cnt h1 {
  font-family: "Gambetta", serif;
  color: #02334d;
  /* white-space: nowrap; */
  margin-left: 15px;

}
.commitment_card_cnt p {
  font-family: "Gambetta", serif;
  color: #000000;
  font-size: 20px;
  margin-left: 15px;
  /* padding: 15px; */
}

.join_bg_cnt {
  background-image: url("../../assets/about4.jpg");
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
url("../../assets/about6.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 100px;
  min-height: 50vh;
}

.join_bg_cnt h1 {
  color: rgb(253, 253, 253);
  font-size: 70px;
  /* text-transform: uppercase; */
  font-family: "Gambetta", serif;
  /* letter-spacing: -3px; */
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  outline: none;
  text-align: left;
  margin-bottom: 20px;
  width: 50vw;
  /* margin-left: 25px; */
}
.join_bg_cnt p {
  color: rgb(235, 231, 231);
  font-size: 20px;
  width: 50vw;
  font-weight: 400;
}




@media  (max-width: 768px) {

  .about_bg_container{
    /* background-color: #02334d; */
    /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; */
    /* min-height: 100vh; */
    height: fit-content;
    padding: 10px;
    padding-top:80px ;
    text-align: center;
    width: fit-content;
  }
  .about_bg_container h1 {
    font-size: 30px;
    /* width: 100vw; */
  }
  .about_bg_container p {
    font-size: 16px;
    width: 90vw;
  }
  .mission_container{
    padding: 10px;
  }
  /* .mission_container h1{
    margin-left: 0px;
  } */
  .choose_bg_cnt{
    width: 100vw;
    padding: 10px;
  }
  .choose_bg_cnt h1 {
   
    font-size: 30px;
    letter-spacing: normal;
    width: 90vw;
    margin-left: 0px;
  }
 
  .choose_bg_cnt p{
    font-size: 16px;
    letter-spacing: normal;
    margin-left: 0px;
    font-family: Arial, Helvetica, sans-serif;
  }
  .choose_card_cnt {
    /* border: 1px solid #d5d5d5; */
    /* padding: 25px; */
    border-radius: 5px;
    margin-bottom: 50px;
    /* background-color: rgba(0, 0, 0, 0.3); */
  }
  .choose_card_cnt h1 {
    width: fit-content;
    color: #fff;
  }
  .commitment_bg_cnt{
    padding: 15px;
  }
  .commitment_bg_cnt h1{
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: normal;
  }
  .commitment_card_cnt h1{
    white-space: normal;
    font-size: 40px;
    letter-spacing: 3px;
    width: fit-content;
    font-family: Arial, Helvetica, sans-serif;
  }
  .commitment_card_cnt p{
    white-space: normal;
    font-size: 16px;
    /* letter-spacing: 3px; */
    width: fit-content;
    font-family: Arial, Helvetica, sans-serif;
  }
  .join_bg_cnt {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .join_bg_cnt h1{
    width: 90vw;
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
  }
  .join_bg_cnt p{
    width: 90vw;
    line-height: 25px;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
  }
  .commitment_bg_cnt h1 {
    font-size: 30px;
  }
}