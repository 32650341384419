.obe_bg_cnt {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../assets/about6.jpg");
  background-size: cover;
  height: 90vh;
  width: 100%;
  /* position: fixed; */
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background-position: center;
}
.obe_heading {
  color: orange;
  width: 100%;
  margin: 20px 5px;

}

.obe_cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: brightness(1.5);
}
.obe_cnt h1 {
  color: #fff;
  font-size: 80px;
  font-family: "Gambetta", serif;
  text-transform: uppercase;
  word-spacing: 20px;
  /* -webkit-text-stroke: #cccccc 3px; */
  letter-spacing: 3px;
  text-align: left;
  width: fit-content;
  margin: 25px;
  text-shadow: 3px 8px 6px rgba(0,0,0,0.4), 0px -5px 11px rgba(255,255,255,0.4);
}
.obe_cnt p{
  color: #cccccc;
  font-size: 20px;
  font-family: "Gambetta", serif;
  /* text-transform: uppercase; */
  /* letter-spacing: 3px; */
}

.obe_card_cnt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: 0.5s ease;
  margin-bottom: 50px;
  margin: 15px;
  border-radius: 5px;
}
.obe_card_img {
  width: 400px;
  height: 250px;
  aspect-ratio: 16/9;
  border-radius: 15px;
}

.bottom_cnt{
  padding: 0px 35px;
}

/* .obe_card_cnt:hover{
  perspective: 500px;
  transform: scaleX(0.7);
  rotate: rotateY(45deg);
} */
.obe_card h1 {
  color: #02334d;
  margin: 30px 0px;
}
.obe_card p {
  color: #000000;
}

.obe_card_cnt2{
  border-left: 10px solid lightgray;
  padding-left: 15px;
  margin-bottom: 25px;
}


@media (max-width: 770px) {
  .obe_bg_cnt {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("../../assets/about6.jpg");
    background-size: cover;
    height: fit-content;
    /* height: 90vh; */
    width: 100%;
    /* position: fixed; */
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background-position: center;
  }
  .bottom_cnt{
    padding:0px 0px;
  }
  .obe_card{
    width: 100%;
  }
  .obe_card p{
    width: 100% !important;
  }
  .obe_cnt h1 {
    color: #fff;
    font-size: 30px;
    font-family: "Gambetta", serif;
    text-transform: uppercase;
    word-spacing: 20px;
    /* -webkit-text-stroke: #cccccc 3px; */
    letter-spacing: 3px;
    text-align: left;
    width: fit-content;
    margin: 25px;
    text-shadow: 3px 8px 6px rgba(0,0,0,0.4), 0px -5px 11px rgba(255,255,255,0.4);
  }
  .obe_card_cnt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
    margin: 0px;
    border-radius: 10px;
  }
  .obe_card_img {
    width: 100%;
    height: 300px;
    aspect-ratio: 16/9;
    border-radius: 15px;
  }
  .obe_card_cnt2{
    margin-left: 10px;
  }
}